import { Showcase } from './Showcase'
import { Slides } from './Slides'
import { Cursor } from './Cursor'
import image1 from '../images/project2-1.png'
import image2 from '../images/project1-22.png'
import image3 from '../images/please.png'
import image4 from '../images/shaderHeader2.jpg'
import image5 from '../images/BRIANHO.jpg'
import vertex from './shaders/vertexShader.glsl'
import fragment from './shaders/fragmentShader.glsl'

import * as THREE from 'three'
import gsap from 'gsap'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { Timeline } from 'gsap/gsap-core'
import barba from '@barba/core'
import barbaCss from '@barba/css'

const container = document.getElementById('app')
const cursor = new Cursor(document.querySelector('.cursor'))
const slidesData = [
	{
		image: image1,
		title: 'Boulevard',
		meta: 'WebGL / Audio Experience',
		link: 'portfolioTree.html',
	},
	{
		image: image2,
		title: 'Synthwave',
		meta: 'WebGL / Audio Visualizer',
		link: 'portfolioSound.html',
	},
	{
		image: image3,
		title: 'Losung',
		meta: 'WebGL / Web Scraper',
		link: 'portfolioShoes.html',
	},
	{
		image: image4,
		title: 'Shaders',
		meta: 'WebGL / Shaders',
		link: 'portfolioShaders.html',
	},
	{
		image: image5,
		title: 'About',
		meta: 'Learn More!',
		link: 'about.html',
	},
]

const slides = new Slides(slidesData)
const showcase = new Showcase(slidesData, {
	onActiveIndexChange: (activeIndex) => {
		slides.onActiveIndexChange(activeIndex)
	},
	onIndexChange: (index) => {
		slides.onMove(index)
	},
	onZoomOutStart: ({ activeIndex }) => {
		cursor.enter()
		slides.appear()
	},
	onZoomOutFinish: ({ activeIndex }) => {},
	onFullscreenStart: ({ activeIndex }) => {
		cursor.leave()
		slides.disperse(activeIndex)
	},
	onFullscreenFinish: ({ activeIndex }) => {},
})

showcase.mount(container)
slides.mount(container)
showcase.render()

window.addEventListener('resize', function () {
	showcase.onResize()
})

window.addEventListener('mousemove', function (ev) {
	showcase.onMouseMove(ev)
})

let pageTransition = () => {
	var timeline = gsap.timeline()

	timeline.to('header', {
		zIndex: 1,
	})

	timeline.to('.page-transition', {
		duration: 0.3,
		height: '100%',
		top: '0%',
	})

	timeline.to('.page-transition', {
		duration: 0.5,
		height: '100%',
		top: '100%',
		delay: 1.0,
	})

	timeline.set('.page-transition', {
		top: '-100%',
	})
}

let mainAnimation = () => {
	var timeline = gsap.timeline()

	timeline.from('.container, .frame__links', {
		duration: 0.6,
		y: 30,
		opacity: 0,
		stagger: {
			amount: 0.4,
		},
		delay: 1.3,
	})
}

let delay = (n) => {
	n = n || 2000
	return new Promise((done) => {
		setTimeout(() => {
			done()
		}, n)
	})
}

barba.use(barbaCss)
const body = document.querySelector('body')

barba.hooks.before((data) => {
	const background = data.current.container.dataset.background
	body.style.setProperty('--page-background', background)
})
barba.init({
	transitions: [
		{
			name: 'home',
			sync: true,
			to: { namespace: ['home'] },
			once() {},
			leave() {},
			enter() {},
		},
		{
			name: 'fade',
			sync: true,
			to: { namespace: ['fade'] },
			leave() {},
			enter() {},
		},
		{
			name: 'clip',
			sync: true,
			to: { namespace: ['clip'] },
			leave() {},
			enter() {},
		},
		{
			name: 'with-cover',
			to: { namespace: ['with-cover'] },
			leave() {},
			enter() {},
		},
	],
})
barba.hooks.after(() => {
	var promise = document.querySelector('video')
	if (promise) {
		promise.play()
	}

	let path = window.location.pathname
	let page = path.split('/').pop()
	if (page === 'about.html') {
		/*------------------------------
		Global Setup
		------------------------------*/
		const canvas = document.querySelector('canvas.webgl')
		const helloMyName = document.querySelector('#helloh1')
		const helloParagraph = document.querySelector('#helloP')
		const helloFindMe = document.querySelector('#youCanFindMe')
		const footer = document.querySelector('.flex-center')
		gsap.fromTo(helloMyName, { opacity: 0 }, { opacity: 1, duration: 0.5 })
		gsap.fromTo(
			helloParagraph,
			{ opacity: 0 },
			{ opacity: 1, duration: 0.5, delay: 0.5 }
		)
		gsap.fromTo(
			helloFindMe,
			{ opacity: 0 },
			{ opacity: 1, duration: 0.5, delay: 1 }
		)
		gsap.fromTo(
			footer,
			{ opacity: 0 },
			{ opacity: 1, duration: 0.5, delay: 1.5 }
		)

		if (getComputedStyle(canvas).opacity == 0) {
			gsap.to(canvas, { autoAlpha: 1, duration: 0.3 })
		}
		const exitBtn = document.querySelector('#exitAbout')
		const scene = new THREE.Scene()
		const renderer = new THREE.WebGLRenderer({
			canvas: canvas,
		})
		renderer.setClearColor(0x0e0e0f)
		const material = new THREE.ShaderMaterial({
			uniforms: {
				uTime: { value: 0 },
				uFreq: { value: 0 },
			},
			vertexShader: vertex,
			fragmentShader: fragment,
		})
		const mesh = new THREE.Points(
			new THREE.SphereBufferGeometry(1.0, 64, 64),
			material
		)
		mesh.position.x = 1.5
		scene.add(mesh)

		/*------------------------------
		Dimensions
		------------------------------*/
		const sizes = {
			width: window.innerWidth,
			height: window.innerHeight,
		}

		const camera = new THREE.PerspectiveCamera(
			75,
			sizes.width / sizes.height,
			0.1,
			100
		)
		camera.position.z = 3
		scene.add(camera)

		renderer.setSize(sizes.width, sizes.height)
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		/*------------------------------
		Resize
		------------------------------*/
		window.addEventListener('resize', () => {
			/*------------------------------
		  Update Sizes
		  ------------------------------*/
			sizes.width = window.innerWidth
			sizes.height = window.innerHeight
			/*------------------------------
		  Update Camera
		  ------------------------------*/
			camera.aspect = sizes.width / sizes.height
			camera.updateProjectionMatrix()
			/*------------------------------
		  Update Renderer
		  ------------------------------*/
			renderer.setSize(sizes.width, sizes.height)
			renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		})

		/*------------------------------
		Controls
		------------------------------*/
		// const controls = new OrbitControls(camera, canvas)
		// controls.enableDamping = true
		/*------------------------------
		Fullscreen Function
		------------------------------*/
		window.addEventListener('dblclick', () => {
			if (!document.fullscreenElement) {
				canvas.requestFullscreen()
			} else {
				document.exitFullscreen()
			}
			gsap.to(canvas, { opacity: 0, duration: 0.3 })
		})
		exitBtn.addEventListener('click', () => {
			// let currOpacity = getComputedStyle(canvas).opacity
			// if (currOpacity == 1) {
			gsap.to(canvas, { autoAlpha: 0, duration: 0.3 })
			// }
		})
		const clock = new THREE.Clock()
		const animate = () => {
			/*------------------------------
		  	Smooth Animation
		  	------------------------------*/
			const elapsedTime = clock.getElapsedTime()
			material.uniforms.uTime.value = elapsedTime
			material.uniforms.uFreq.value = 3.0
			/*------------------------------
		  	Update Controls
		  	------------------------------*/
			// controls.update()

			/*------------------------------
		  	Update Meshes
		  	------------------------------*/
			mesh.rotation.x = elapsedTime * 0.75
			mesh.rotation.y = elapsedTime * 0.75

			/*------------------------------
		  	Render
		  	------------------------------*/
			renderer.render(scene, camera)
			/*------------------------------
		  	Core
		 	------------------------------*/
			window.requestAnimationFrame(animate)
		}

		animate()
	}

	const container = document.getElementById('app')
	const cursor = new Cursor(document.querySelector('.cursor'))
	const slidesData = [
		{
			image: image1,
			title: 'Boulevard',
			meta: 'WebGL / Audio Experience',
			link: 'portfolioTree.html',
		},
		{
			image: image2,
			title: 'Synthwave',
			meta: 'WebGL / Audio Visualizer',
			link: 'portfolioSound.html',
		},
		{
			image: image3,
			title: 'Losung',
			meta: 'WebGL / Web Scraper',
			link: 'portfolioShoes.html',
		},
		{
			image: image4,
			title: 'Shaders',
			meta: 'WebGL / Shaders',
			link: 'portfolioShaders.html',
		},
		{
			image: image5,
			title: 'About',
			meta: 'Learn More!',
			link: 'about.html',
		},
	]

	const slides = new Slides(slidesData)
	const showcase = new Showcase(slidesData, {
		onActiveIndexChange: (activeIndex) => {
			slides.onActiveIndexChange(activeIndex)
		},
		onIndexChange: (index) => {
			slides.onMove(index)
		},
		onZoomOutStart: ({ activeIndex }) => {
			cursor.enter()
			slides.appear()
		},
		onZoomOutFinish: ({ activeIndex }) => {},
		onFullscreenStart: ({ activeIndex }) => {
			cursor.leave()
			slides.disperse(activeIndex)
		},
		onFullscreenFinish: ({ activeIndex }) => {},
	})

	showcase.mount(container)
	slides.mount(container)
	showcase.render()

	window.addEventListener('resize', function () {
		showcase.onResize()
	})

	window.addEventListener('mousemove', function (ev) {
		showcase.onMouseMove(ev)
	})
})
